/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
		  var s = $(".nav-primary");
			var pos = s.position();
			$(window).scroll(function() {
				var windowpos = $(window).scrollTop();
				if (windowpos >= pos.top) {
					s.addClass("sticky");
				} else {
					s.removeClass("sticky");
				}
			});

			// hide our element on page load
			 $('.alignright').css('opacity', 0);

			 $('.alignright').waypoint(function() {
				  $('.alignright').addClass('fadeInRight');
			 }, { offset: '100%' });

			 $('.service-1').css('opacity', 0);

			 $('.service-1').waypoint(function() {
				  $('.service-1').addClass('fadeInLeft');
			 }, { offset: '100%' });

			 $('.service-2').css('opacity', 0);

			 $('.service-2').waypoint(function() {
				  $('.service-2').addClass('fadeInUp');
			 }, { offset: '75%' });

			 $('.service-3').css('opacity', 0);

			 $('.service-3').waypoint(function() {
				  $('.service-3').addClass('fadeInRight');
			 }, { offset: '50%' });

			 $('.faq-1').css('opacity', 0);

			$('.faq-1').waypoint(function() {
				 $('.faq-1').addClass('fadeInLeft');
			}, { offset: '100%' });

			$('.faq-2').css('opacity', 0);

			$('.faq-2').waypoint(function() {
				 $('.faq-2').addClass('fadeInUpBig');
			}, { offset: '75%' });

			$('.faq-3').css('opacity', 0);

			$('.faq-3').waypoint(function() {
				 $('.faq-3').addClass('fadeInRight');
         }, { offset: '50%' });


         $("path, circle").hover(function(e) {
            $("#info-box").css("display", "block");
            $("#info-box").html($(this).data("info"));
         });

         $("path, circle").mouseleave(function(e) {
            $("#info-box").css("display", "none");
         });

         $("[data-state='1']").on('mouseenter', function(){
            $("[data-state='1']").each(function(index) {
               $(this).addClass("state-hover");
            });

         });
         $("[data-state='1']").on("mouseleave", function() {
            $("[data-state='1']").each(function(index) {
               $(this).removeClass("state-hover");
            });
         });

                  $("[data-state='2']").on('mouseenter', function(){
            $("[data-state='2']").each(function(index) {
               $(this).addClass("state-hover");
            });

         });
         $("[data-state='2']").on("mouseleave", function() {
            $("[data-state='2']").each(function(index) {
               $(this).removeClass("state-hover");
            });
         });

                  $("[data-state='3']").on(
                     "mouseenter",
                     function() {
                        $("[data-state='3']").each(function(
                           index
                        ) {
                           $(this).addClass("state-hover");
                        });
                     }
                  );
                  $("[data-state='3']").on(
                     "mouseleave",
                     function() {
                        $("[data-state='3']").each(function(
                           index
                        ) {
                           $(this).removeClass("state-hover");
                        });
                     }
                  );
          if($(".interactive-map-container").length){
         $(document)
            .mousemove(function(e) {
               $("#info-box").css("top", e.pageY - $("#info-box").height() - 520);
               $("#info-box").css("left", e.pageX - $("#info-box").width() / 2 - $(".interactive-map-container")[0].getBoundingClientRect().left);
            })
            .mouseover();
          }
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
